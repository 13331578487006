import Abele, { Component } from 'abele'
import Slider from 'abele-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
// import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import './carousel.css'

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: 'block', background: 'red' }}
//       onClick={onClick}
//     >
//       Test
//     </div>
//   )
// }

export default class Carousel extends Component {
  render() {
    const settings = {
      arrows: false,
      dots: false,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 500,
      pauseOnHover: false,
      cssEase: 'ease-in-out'
      // lazyLoad: 'progressive'
    }
    return (
      <div>
        <div className="row" data-equalizer="contents">
          <div
            className="columns medium-4 myBg"
            // text-center="true"
            data-equalizer-watch="contents"
          >
            <div className="myKenburns">
              <div className="mySlick">
                <Slider {...settings}>
                  <div>
                    <img
                      alt=""
                      className="fadeIn slickImage"
                      src={'images/carousel/2932.jpg'}
                    />
                  </div>
                  <div>
                    <img
                      alt=""
                      className="slickImage"
                      src={'images/carousel/2963.jpg'}
                    />
                  </div>
                  <div>
                    <img
                      alt=""
                      className="slickImage"
                      src={'images/carousel/1803.jpeg'}
                    />
                  </div>
                  <div>
                    <img
                      alt=""
                      className="slickImage"
                      src={'images/carousel/1761.jpg'}
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
